<template>
    <a-modal width="80%" wrap-class-name="full-modal" :visible="true" :footer="null" title="History">
        <div v-if="!isLoading">
            <a-row :gutter="25" v-for="(change, index) in historyData" :key="index" class="history-row">
                <a-col span="24">
                    <h3>{{ formatdate(change.date) }}</h3>
                </a-col>
                <a-col span="24">
                    <TableWrapper class="table-data-view table-responsive">
                        <a-table :columns="columns" :dataSource="change.changes" :pagination="false" />
                    </TableWrapper>
                </a-col>
            </a-row>
        </div>
        <a-spin v-else size="large" />
    </a-modal>
</template>
<script setup>
import { TableWrapper } from '../../view/styled';
import moment from "moment";
import { defineProps, computed, onMounted } from 'vue';
import { useStore } from "vuex";
const { state, dispatch } = useStore();
const historyData = computed(() => state.historyApi.data);
const isLoading = computed(() => state.historyApi.loading);


const props = defineProps({
    entity: String,
    entityId: String,
});

onMounted(() => {
    const target = {
        entity: props.entity,
        entityId: props.entityId,
    }
    dispatch('historyGetData', target);
    console.log(historyData);
});


// Table 
const columns = [
    {
        title: 'Name',
        dataIndex: 'fieldName',
        key: 'fieldName',
    },
    {
        title: 'Old Value',
        dataIndex: 'oldValue',
        key: 'oldValue',
        customRender: ((date) => formatdate(date))
    },
    {
        title: 'New value',
        dataIndex: 'newValue',
        key: 'newValue',
        customRender: ((date) => formatdate(date))
    },
];

const formatdate = (date) => moment(date).format("DD/MM/YYYY");



</script>
<style lang="scss">
.history-row {
    margin-top: 16px;
}

.history-row {
    th {
        text-align: left !important;
    }
}
</style>